<template>
  <v-navigation-drawer
    color="f5f5f5"
    :value="drawerPosts"
    app
    clipped
    right
    width="400px"
  >
    <v-container fluid class="px-0 pb-0">
      <div class="px-6 d-flex mb-4">
        <h1 class="text-h5 mb-4">POSTS</h1>
        <v-spacer></v-spacer>
        <v-btn @click="addPost" fab small class="xbColor white-text" depressed
          ><v-icon>mdi-plus</v-icon></v-btn
        >
      </div>
      <DialogPostImage
        v-if="dialogPostImage"
        :dialogPostImage.sync="dialogPostImage"
        :image="image"
      />
      <DialogPost
        v-if="dialogPost"
        :dialogPost.sync="dialogPost"
        :edit="edit"
        :item="post"
      />
      <DialogPostCurtida
        v-if="dialogPostCurtida"
        :dialogPostCurtida.sync="dialogPostCurtida"
        :post_id="post_id"
      />

      <v-btn-toggle
        v-model="filtro"
        tile
        mandatory
        group
        class="d-flex justify-center align-center"
      >
        <v-badge
          :class="filtro == 0 ? 'borda' : ''"
          class="mr-4"
          color="white"
          overlap
        >
          <template v-if="filtro == 0 && !loadingPosts" v-slot:badge>
            <span class="primary--text font-weight-bold">
              {{ posts.length }}
            </span>
          </template>
          <v-btn text class="text-caption flex-grow-1" :value="0">
            Pendentes
          </v-btn>
        </v-badge>

        <v-badge
          :class="filtro == 1 ? 'borda' : ''"
          class="mr-4"
          color="white accent-4"
          bordered
          overlap
        >
          <template v-if="filtro == 1 && !loadingPosts" v-slot:badge>
            <span class="primary--text font-weight-bold">
              {{ posts.length }}
            </span>
          </template>
          <v-btn text class="text-caption flex-grow-1" :value="1">
            Aprovados
          </v-btn>
        </v-badge>

        <v-badge
          :class="filtro == 2 ? 'borda' : ''"
          color="white"
          bordered
          overlap
        >
          <template v-if="filtro == 2 && !loadingPosts" v-slot:badge>
            <span class="primary--text font-weight-bold">
              {{ posts.length }}
            </span>
          </template>
          <v-btn text class="text-caption flex-grow-1" :value="2">
            Reprovados
          </v-btn>
        </v-badge>
      </v-btn-toggle>

      <v-divider class="mb-0"></v-divider>

      <template v-if="!loadingPosts">
        <v-card
          flat
          style="height: calc(100vh - 182px); overflow: auto"
          class="pa-3"
        >
          <v-card
            v-for="(post, index) in posts"
            :key="index"
            :class="{ 'mb-3': posts.length - 1 != index }"
            style="overflow: hidden"
            :disabled="acaoPost && acaoPostIndex == index"
            :loading="acaoPost && acaoPostIndex == index"
          >
            <v-row @click="openPost(post)" style="cursor: pointer" no-gutters>
              <!-- Headers -->
              <v-col cols="12">
                <div class="d-flex align-center justify-space-between pa-1">
                  <div class="d-flex align-center my-2">
                    <div>
                      <v-img
                        class="user-avatar"
                        :src="post.user_avatar"
                      ></v-img>
                    </div>
                    <div class="d-flex align-center">
                      <span
                        class="text-body-2 text-uppercase ml-2 font-weight-bold"
                      >
                        {{ post.user_nome | excerpt(12) }}
                      </span>
                      <div
                        v-if="post.user_vip"
                        class="d-flex align-center pl-2"
                      >
                        <span class="text-caption red--text font-weight-bold">
                          - VIP
                        </span>
                        <v-icon color="red" dark> mdi-star </v-icon>
                      </div>
                    </div>
                  </div>

                  <span class="text-caption">
                    {{ formatDate(post.updated_at) }}
                  </span>
                </div>
              </v-col>

              <!-- Thumbnail -->
              <v-col style="cursor: pointer" cols="12">
                <v-card
                  v-if="post.thumbnail"
                  @click.stop="post.thumbnail ? verImage(post.thumbnail) : ''"
                  flat
                  tile
                  class="accent pa-0"
                  height="200px"
                  style="position: relative"
                >
                  <v-img height="100%" cover :src="post.thumbnail"></v-img>
                </v-card>

                <v-card
                  v-else
                  flat
                  tile
                  class="accent pa-3 d-flex align-center justify-center"
                  height="200px"
                >
                  <v-icon large>mdi-image</v-icon>
                </v-card>
              </v-col>

              <!-- Curtidas -->
              <v-col cols="12">
                <div class="d-flex pa-1">
                  <v-icon
                    v-if="post.destaque"
                    color="yellow darken-1"
                    class="mr-1"
                    >mdi-star</v-icon
                  >
                  <v-spacer></v-spacer>
                  <div class="d-flex align-center">
                    <span class="text-caption">
                      curtidas {{ post.qtde_curtidas }}
                    </span>
                    <!-- <v-btn
                      disabled
                      @click="post.user_curtiu ? descurtir(post) : curtir(post)"
                      small
                      fab
                      text
                    >
                      <v-icon>{{
                        post.user_curtiu ? "mdi-heart" : "mdi-heart-outline"
                      }}</v-icon>
                    </v-btn> -->
                  </div>
                </div>
              </v-col>

              <!-- Conteudo -->
              <v-col class="pa-3">
                <div class="text-body-2" style="word-break: break-word">
                  {{ post.conteudo }}
                </div>
              </v-col>
            </v-row>

            <v-divider></v-divider>

            <v-card-actions class="pa-0" style="overflow: hidden">
              <v-row no-gutters>
                <v-col v-show="filtro != 2">
                  <v-btn
                    depressed
                    tile
                    block
                    color="white"
                    class="primary--text"
                    @click="reprovaPost(post.id, index)"
                  >
                    <v-icon>mdi-thumb-down</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="auto" v-show="filtro == 0">
                  <v-divider
                    style="margin: 0 1px !important"
                    vertical
                  ></v-divider>
                </v-col>
                <v-col v-show="filtro != 1">
                  <v-btn
                    depressed
                    tile
                    block
                    color="white"
                    class="xbColor--text"
                    @click="aprovaPost(post.id, index)"
                  >
                    <v-icon>mdi-thumb-up</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-card>
      </template>
      <div v-else class="d-flex flex-column align-center justify-center">
        <v-progress-circular indeterminate></v-progress-circular>
        <span class="text--caption">carregando...</span>
      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { parseISO, formatDistanceToNow } from "date-fns";
import dateLocalePortugues from "date-fns/locale/pt-BR";
import { like, dislike } from "@/api/admin/posts.js";

export default {
  name: "DrawerPosts",

  props: {
    drawerPosts: {
      type: Boolean,
      required: true,
    },
  },

  components: {
    DialogPost: () => import("./DialogPost.vue"),
    DialogPostCurtida: () =>
      import("@/views/home/components/DialogPostCurtida.vue"),
    DialogPostImage: () =>
      import("@/views/home/components/DialogPostImage.vue"),
  },

  data() {
    return {
      filtro: 0,
      acaoPost: false,
      acaoPostIndex: null,
      loadingPosts: true,
      loadingCurtida: false,
      foiCurtido: false,
      dialogPost: false,
      dialogPostCurtida: false,
      post: {},
      edit: false,
      post_id: null,
      dialogPostImage: false,
      image: null,
    };
  },

  computed: {
    ...mapState("Posts", {
      posts: (state) => state.posts,
    }),
    ...mapState("Posts", {
      postsLength: (state) => state.posts.length,
    }),
    ...mapState("Usuario", {
      usuario: (state) => state.usuario,
    }),
  },

  watch: {
    filtro: {
      handler() {
        this.loadPosts();
      },
      immediate: true,
    },
  },

  methods: {
    verImage(item) {
      this.dialogPostImage = true;
      this.image = item;
    },
    addPost() {
      this.dialogPost = true;
      this.edit = false;
    },
    openPost(item) {
      this.dialogPostCurtida = true;

      this.post_id = item.id;
    },
    editPost(item) {
      this.dialogPostCurtida = true;
      this.edit = true;
      this.post_id = item.id;
    },
    ...mapActions("Posts", {
      fetchPosts: "getPosts",
      aprovarPost: "aprovarPost",
      reprovarPost: "reprovarPost",
    }),

    async loadPosts() {
      this.loadingPosts = true;
      await this.$store.dispatch("Posts/getPosts", this.filtro);
      this.loadingPosts = false;
    },

    formatDate(date) {
      let options = {
        includeSeconds: true,
        locale: dateLocalePortugues,
        addSuffix: true,
      };
      return formatDistanceToNow(parseISO(date), options);
    },

    async aprovaPost(post_id, index) {
      this.acaoPostIndex = index;
      this.acaoPost = true;

      await this.aprovarPost(post_id);
      this.posts.splice(index, 1);
      this.acaoPost = false;
      this.acaoPostIndex = null;
    },

    async reprovaPost(post_id, index) {
      this.acaoPostIndex = index;
      this.acaoPost = true;
      await this.reprovarPost(post_id);
      this.posts.splice(index, 1);
      this.acaoPost = false;
      this.acaoPostIndex = null;
    },

    curtir(item) {
      this.loadingCurtida = true;

      const postCurtido = {};
      postCurtido.post_id = item.id;

      like(postCurtido)
        .then(() => {
          this.loadingCurtida = false;
          item.qtde_curtidas++;
          item.user_curtiu = true;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCurtida = false;
        });
    },

    descurtir(item) {
      this.loadingCurtida = true;

      dislike(item.id)
        .then(() => {
          this.loadingCurtida = false;
          item.qtde_curtidas--;
          item.user_curtiu = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCurtida = false;
        });
    },
  },

  mounted() {
    // this.$echo.channel("posts").listen(".postCreate", (post) => {
    //   console.log("echo post");
    //   this.$store.dispatch("Posts/pushPost", post);
    // });
  },

  beforeDestroy() {
    // this.$echo.channel("posts").stopListening(".postCreate");
    // this.$echo.channel("posts").unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.v-item--active {
  color: #feb60c;
}
.borda {
  ::v-deep .v-badge__badge {
    border: 1px solid #0000001f !important;
  }
}
.user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}
</style>
